<template>
  <div class="mm-login-button">
    <LoaderButton
      v-if="!isUserLoggedIn"
      id="login_button"
      class="mm-login-button-login"
      :loader="loaderShowModal"
      @confirm="onShowLoginModal"
    >
      Войти
      <SvgIcon :src="srcIcon" />
    </LoaderButton>

    <div v-else>
      <AccountPopup>
        <div
          id="user_full_name"
          class="mm-login-button-user"
        >
          {{ userName }}
          <div class="mm-login-button-user__icon">
            <SvgIcon :src="srcIcon" />
            <div
              v-if="backNotificationService?.unreadCount?.value"
              class="mm-login-button-user__icon__indicator"
            />
          </div>
        </div>
      </AccountPopup>
    </div>

    <LazyNotificationsDrawer v-if="isUserLoggedIn" />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from 'store/user.store';
import { BackNotificationService } from 'services/notification.service';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { AuthManagerService } from 'services/auth/authManager.service';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { getFullNameAbbreviation } from 'shared/utils/fullNameAbbreviation.util';
import SvgIcon from 'shared/components/SvgIcon.vue';
import AccountPopup from './AccountPopup.vue';
import LoaderButton from 'shared/components/LoaderButton.vue';
import Loader from 'shared/utils/loaderHelper.util';

const props = defineProps<{
  isErrorPage?: boolean;
}>();

const userStore = useUserStore();
const { isUserLoggedIn, userInfo } = storeToRefs(userStore);
const backNotificationService = inject<BackNotificationService>(BackNotificationService.getServiceName());

const loaderShowModal = Loader.getReactiveInstance();

const userName = computed(() => getFullNameAbbreviation(userInfo?.value?.lastName, userInfo?.value?.name, userInfo?.value?.patronymic, userInfo?.value?.email));

const srcIcon = computed(() => (isUserLoggedIn.value ? EIconPath.NavigationLkSvg : EIconPath.NavigationLogin20pxSvg));

const { showLoginModal } = useAuthFnHelper();

async function onShowLoginModal() {
  try {
    loaderShowModal.activate();
    await showLoginModal(loginModalCallback, undefined, !props.isErrorPage, false);
  } finally {
    loaderShowModal.deactivate();
  }
}

async function loginModalCallback(): Promise<void> {
  await nextTick(async () => await AuthManagerService.checkSupplierRedirect());
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-login-button {
  &-login {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent !important;
    padding: 0 !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    color: $text-dark-green;

    :deep(svg) {
      path {
        fill: $text-dark-green;
      }
    }
  }

  &-user {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__icon {
      display: flex;
      align-items: center;
      position: relative;

      &__indicator {
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: $notification-indicator-bg;
        border-radius: 12px;
        right: -3px;
        top: -6px;
      }
    }
  }

  :deep(.mm-account) {
    .popper {
      background-color: transparent;
      padding: 0;
    }
  }
}
</style>

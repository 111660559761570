<template>
  <div
    class="header--mobile"
    :class="{
      'mm-supplier-header-order-split--mobile': orderSplit,
      'header--mobile--oci': ociMode
    }"
  >
    <ul
      v-if="mobileMenu?.menuItems?.value"
      class="header__list"
    >
      <li
        v-for="(item, key) in (mobileMenu.menuItems.value || [])"
        :key="key"
        class="header__item"
        :class="{ 'header__item-link--active': isActiveLink(item) || isDefaultActive && item?.isDefault, 'header__item-link--disabled': item?.disabled }"
        @click="mobileMenu.onMenuItemClick(item)"
      >
        <div class="header__item__icon">
          <SvgIcon
            :src="item.icon"
            class="header__item__icon-svg"
          />
          <div
            v-if="(backNotificationService?.unreadCount?.value && item.link === '/profile/')"
            class="notification-indicator"
          />
        </div>
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useMobileMenu } from 'composables/useMoblieMenu';
import { RouterHelper } from 'shared/utils/routerHelper.util';
import { BackNotificationService } from 'services/notification.service';
import { IHeaderList } from 'shared/models/headerList.model';
import { useUserStore } from 'store/user.store';
import { useIsOrderSplit } from 'composables/useIsOrderSplit';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const props = defineProps<{
  ociMode?: boolean;
}>();

const watchSubscription = new WatchSubscription();
const backNotificationService = inject<BackNotificationService>(BackNotificationService.getServiceName());
const route = useRoute();

let mobileMenu = useMobileMenu(undefined, undefined, props.ociMode);

const isDefaultActive = computed(() => mobileMenu?.menuItems?.value?.every((linkItem) => !isActiveLink(linkItem) || linkItem?.isDefault));
const orderSplit = useIsOrderSplit();

function isActiveLink(linkItem: IHeaderList): boolean {
  return RouterHelper.includePathWithoutRoot(linkItem.link, route) || mobileMenu.isActive(linkItem, route?.fullPath);
}

watchSubscription.add(
  watch(
    () => useUserStore().isUserLoggedIn,
    () => mobileMenu = useMobileMenu(),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.header__list {
  padding: 0;
}

.header__item-link--disabled {
  color: $text-disabled;
  pointer-events: none;
  touch-action: none;

  .header__item__icon {
    .header__item__icon-svg {
      :deep(path) {
        fill: $text-disabled;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-supplier-header-order-split--mobile {
    border: none !important;
  }
}
</style>
